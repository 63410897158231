import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

import interlakenImageUrl from "../images/InterlakenPrivatpersonen.jpg";

const Item = styled.li`
  line-height: 1.5;
`;
const Toolbar = styled.div`
  display: flex;
  flex-direction: row;
  @media(max-width: 700px) {
    flex-direction: column !important;
  }
`;
const Image = styled.img`
  width: 50%;
  float: left;
  height: 60vh;
  object-fit: cover;
  @media(max-width: 700px) {
    width: 100% !important;
  }
`;
const Corner = styled.div`
  width: 50%;
  margin-right: 10px;
  @media(max-width: 700px) {
    width: 100% !important;
  }
`;


export default () => (
  <Layout>
    <Toolbar>
      <Corner>
        <h1>Privatpersonen</h1>
        Für Privatpersonen bieten wir folgende Versicherungen und Vorsorge an:
        <ul>
          <Item>Privathaftpflichtversicherung</Item>
          <Item>Hausratversicherung</Item>
          <Item>Reiseversicherung</Item>
          <Item>Motorfahrzeugversicherung</Item>
          <Item>Schiffsversicherung</Item>
          <Item>Wertsachenversicherung</Item>
          <Item>Gebäudeversicherung</Item>
          <Item>Mietkautionsversicherung</Item>
          <Item>Erdbebenversicherung</Item>
          <Item>Sparversicherung</Item>
          <Item>Todesfallversicherung</Item>
          <Item>Erwerbsausfallversicherung</Item>
          <Item>Säule 3a / 3b</Item>
          <Item>Krankenkasse</Item>
          <Item>Pension</Item>
          <Item>Rechtschutzversicherung</Item>
        </ul>
      </Corner>
      <Image src={interlakenImageUrl} alt="Privatpersonen" />
    </Toolbar>
  </Layout>
);
